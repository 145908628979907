input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select.form-control {
  -webkit-appearance: menulist;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

Button {
  border-radius: 10px;
}

.btn {
  border-radius: 10px;
}

.btn-default {
  background-color: #f57231;
  color: #fff;
  border: #fe5c07;
}

.btn-white {
  background-color: #fff;
  color: #000;
  border: #fe5c07;
}

.btn-default:hover {
  background-color: #fe5c07;
  color: #fff;
}

.btn-white:hover {
  background-color: #ccc;
  color: #000;
}

.text-warning {
  color: #f57231 !important;
}

.bg-warning {
  background-color: #f57231 !important;
}
.bg-black {
  background-color: #000 !important;
}

.bg-grey {
  background-color: #f2f2f2;
}
.bg-distinction {
  background-color: purple;
}

.border-warning {
  border-color: #fe5c07 !important;
}

img {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.btn-link {
  text-decoration: none;
  border: 2px solid #f2f2f2;
  padding: 3px;
  font-size: small;
}

.btn-link:hover {
  background-color: #f2f2f2;
  color: rgba(245, 114, 49, 0.9) !important;
  font-weight: bold;
}

#img-logo {
  max-width: 300px;
  border-radius: 0px;
}

#club-name {
  font-size: 1.5rem;
}

main {
  min-height: 80vh;
}

.center-el {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, 50%);
}

video:focus {
  outline: none;
}

.small-video {
  max-width: 50%;
  float: right;
  margin: 0.3rem;
}

.wrap-text {
  display: inline;
}

.error {
  color: red;
}

input[type="file"] {
  display: none;
}

.custom-fileupload-button {
  cursor: pointer;
}

.event-image {
  aspect-ratio: 16 / 9;
}

.profile-img {
  object-fit: contain;
  aspect-ratio: 1/1;
  background-color: rgb(242, 242, 242, 1);
}

/* mouse hover */
.mouse-hover-pointer:hover {
  cursor: pointer;
}

/* mini promo */
#simon-profile-round {
  max-width: 120px;
  border-radius: 50%;
}

.nav.nav-pills .nav-link.active {
  color: orange;
}

.modal-90w {
  margin: auto;
  margin-top: 20px;
  max-width: 80% !important;
}

.text-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.max-width-30 {
  max-width: 30px;
}
.max-width-100 {
  max-width: 100px;
}
.max-width-200 {
  max-width: 200px;
}
.max-width-300 {
  max-width: 300px;
}

.max-width-500 {
  max-width: 500px;
}
.max-width-600 {
  max-width: 600px;
}
.max-width-700 {
  max-width: 700px;
}

.profileImg {
  height: 150px;
  max-width: 200px;
  overflow: hidden;
}

td {
  text-align: center;
  vertical-align: middle;
}

.pointer {
  cursor: pointer;
}

.light-link {
  color: blue;
}

.light-link:hover {
  color: blue;
  text-decoration: underline;
}

.carousel-caption {
  background: rgba(245, 114, 49, 0.9) !important;
  padding: 5px 20px 5px 20px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #f57231;
}

.nav-item {
  margin-right: 0px !important;
}

.table-font-size {
  font-size: small;
}

.notification {
  margin-top: -5px;
  margin-left: -10px;
  position: absolute;
  border-radius: 50%;
  background-color: red;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
}
.notification-container {
  position: relative;
}

.stock-notification {
  margin-top: -40px;
  margin-left: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: red;
  width: 25px;
  height: 25px;
  text-align: center;
  color: white;
}

/* div[class*="--Results"] {
  background-color: #f57231 !important;
  visibility: visible;
}

div[class*="--Results"]:not([aria-hidden="true"]) {
  visibility: visible !important;
} */

@media (max-width: 1000px) {
  #club-name {
    display: none;
  }
  #img-logo {
    /* max-width: 120px; */
    display: none;
  }
}

@media (max-width: 767px) {
  #join-now-btn {
    display: none;
  }
  #img-logo {
    display: inline-block;
    max-width: 200px;
  }
  #showcase {
    height: 300px;
  }
}
@media (max-width: 600px) {
  .small-video {
    max-width: 100%;
  }
}
@media (max-height: 420px) {
  #showcase {
    min-height: 350px !important;
  }
}
